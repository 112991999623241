.pro-menu-item .pro-icon-wrapper .MuiSvgIcon-root {
    color: #ffffff !important;
    fill: #ffffff !important;
}

.pro-menu-item .MuiSvgIcon-root[data-testid="FolderCopyOutlinedIcon"] {
    color: #ffffff !important;
    fill: #ffffff !important;
}

.tally31 {

   width: 400px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px

}

.tally31 {

    width: 420px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px
}

.tally32 {
    border: 2px solid;
    padding: 7px;
    text-align: center;
    width: 100px
   
}

/* layout */

.kingdom {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    max-width: 1200px;

}

.centerDiv {
    margin: 15px;
    display: flex;
    align-items: left;
    flex-direction: column;
    padding: 10px;
    width: 100%
}

.rowPlain {
    display: flex;
    flex-direction: row;
    /* gap: 3em; */

}

.rowCenter {
    display: flex;
    justify-content: center;
    flex-direction: row;

}

.columnPlain {
    display: flex;
    flex-direction: column;
    /* width: 100%; */
    align-items: left;
}

.columnPlain2 {
        display: flex;
        flex-direction: column;
        margin-left: 20px
}

.columnInARow {
    border-right: 1px solid;
    margin-right: 10px;

}

.phylum {
    font-family: 'Lato', sans-serif;
    font-size: 1.5em;
    padding-top: 5px;
    margin: 10px;
    border-radius: 5px;
    border: .5px solid #1c3ba2;
    color: #fff;
    background-color: #1c3ba2;
    width: 250px;
    height: 5em;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.phylumButton {
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    display: inline-block;
    width: 100px;
    /* Ensures both buttons take up the same width */
    color: #fff;
    background: none;
    border: none;
    /* Removes any border */
    box-shadow: none;
    /* Removes any drop shadow */
    outline: none;
    /* Removes focus outline */
    appearance: none;
    /* Removes default button styling in some browsers */
    -webkit-appearance: none;
    /* Removes default button styling in WebKit-based browsers */
    -moz-appearance: none;
    /* Removes default button styling in Firefox */
}

.dateButton {
    border: none;
    margin-bottom: 15px;
    background: rgba(241, 239, 241, 0.01);
    border-radius: 20px;
    color: rgba(233, 165, 30, 0.8);
    font-size: 10px;
    /* Keeps button text small */
    transition: all 0.3s ease;
    letter-spacing: 1px;
    padding: 5px 10px;
    /* Ensures proper spacing */
    min-width: 50px;
    /* Keeps button width uniform */
}

.dateButton .year {
    font-size: 16px !important;
    /* Makes year text larger */
    font-weight: bold;
    line-height: 1;
    display: block;
    /* Ensures it doesn't affect button size */
}

.pageButton {
        font-family: 'Lato', sans-serif;
        font-size: 14px;
        display: inline-block;
        width: 100px;
        /* Ensures both buttons take up the same width */
        color: #fff;
        background: none;
        border: none;
}

.sourceStatL,
.sourceStatR {
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    width: 200px;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    box-sizing: border-box

}

.sourceStatL {
border-right: 1px solid
}

.sourceStatR {
    margin-left: 10px;
    /* Keep this as it's a valid margin */
}

.sourceStatHeader {
font-weight: 600;
font-size: 18px
}

/* BOXES */

.columnBox {
    display: flex;
    flex-direction: column;
    align-items: left;
    border: 1px solid;
    padding: 8px;
    width: 40%;
    margin-bottom: 5px
}

.rowStats {
    display: flex;
    flex-direction: row;
}

.topHeader {
    width: 100%;
    margin: 10px 10px 0px 0px;
    background: linear-gradient(90deg, 
    hsla(40, 27%, 55%, 1) 52%, hsla(40, 27%, 55%, 1) 70%,
     hsla(200, 10%, 94%, 1) 110%);
    color: white;
    font-weight: 600;
    padding-left: 10px
}

.topHeader2 {
    width: 305px;
    background: linear-gradient(90deg, hsla(40, 27%, 55%, 1) 
    52%, hsla(40, 27%, 55%, 1) 70%, hsla(200, 10%, 94%, 1) 110%);
    color: white;
    font-weight: 600;
    padding-left: 10px
}

.responsive-div {
    width: 100%;
    max-width: 100%;
    overflow-wrap: break-word;
    box-sizing: border-box;
    font-size: 1em;
}

.campaignColumn {
        display: flex;
        flex-direction: column;
        /* align-items: flex-start; */
        justify-content: flex-start;
        color: white;
        width: 100%
}

.annualChart {
    height: 90vh;
    width: 90%;
    margin-top: 10px;
    padding: 0;
    background-color: white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

/* text */

#lato {
    font-family: 'Lato', sans-serif;
    word-spacing: 1.5px
}

.thick-outline {
    font-family: sans-serif;
    font-size: 48px;
    /* Adjust font size as needed */
    font-weight: 900;
    /* Makes the font super thick */
  
    /* Inner font color */
    /* text-shadow: */
        /* -2px -2px 0 rgb(190, 23, 120), */
        /* Top-left shadow for outline */
        /* 2px -2px 0 black, */
        /* Top-right shadow for outline */
        /* -2px 2px 0 black, */
        /* Bottom-left shadow for outline */
        /* 2px 2px 0 black; */
    /* Bottom-right shadow for outline */
}

.h1sk {
    font-size: 18px;
    font-weight: 600;
}

.h1bsk {
    font-size: 16px;
    font-weight: 900;
    letter-spacing: 1.5px;
}


.h2sk {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 2px;
}

.h3sk {
    font-size: 12px;
    font-weight: 600;
  
}

.campaignData {
    color: #F6B65C;
    font-size: 11px
}

.custom-icon-white {
    color: #ffffff !important;
}

.campaignStat {
    margin-left: 10px
}

.custom-icon {
    color: #ffffff !important;
    /* Force the color to white */
}

/* button */
.dropdown {
background: none;
/* border: 1px solid; */
padding: 2px;
line-height: 10px;
border-radius: 20px
}

.loginButton {
    width: 120px;
    margin-left: 62px;
}

.search-box {
    margin: 10px 0px;
    line-height: 20px
}

/* table */
.tableSK {
    border-spacing: 0;
    border-collapse: collapse;
  }

/* Charts////////////////// */
.pieChart1 {
    width: 300px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.barChartWidth {
    width: 1000px;
}

/* Mobile devices ////////// */
@media (max-width: 480px) {
    
    .kingdom {
        flex-direction: column;
        /* align-items: center; */
        justify-content: center;
    }
    
    .columnPlain2 {
        margin-left: 0px
    }

    .responsive-div {
        max-width: 200px;
        font-size: .7em
    }
    .centerDiv {
        justify-content: left;
        margin: 15px 2px 15px 2px;
        padding-left: 10px
    }

    .sourceStatR {
        margin-left: 0
    }

    .columnInARow {
        border-right: none;
        margin-right: none
    }

    .rowPlain {
        flex-direction: column;
        background-color: #1c3ba2;
    }

    .pieChart1 {
        width: 50%
    }

    .barChartWidth {
        width: 500px;
        
    }

    .columnBox {
        width: 100%
    }
}

/* iPads, Tablets ////////////////////////////////////////////////////////////////////////// */
@media (min-width: 481px) and (max-width: 1024px) {
    .centerDiv {
        justify-content: left;
        margin: 15px 2px 15px 2px
    }
    .rowPlain {
        flex-direction: column;
   
    }
    .barChartWidth {
        width: 400px;

    }
    .columnInARow {
        border-right: none;

    }

    .columnPlain2 {
        margin-left: 0px
    }

    .sourceStatL {
        border-right: none
    }

}

/* Extra large screens, TV //////////////////////////////////////// */
@media (min-width: 1201px) {
    .kingdom {
        justify-content: left;
        }

    .searchBox {
        font-size: 1.1em;
    }
    .rowPlain {
        width: 60%
    }

    .columnPlain {
        display: flex;
        flex-direction: column;
        width: 30%
    }
}

/* Small screens, laptops ////////////////////////////////////////////// */
/* @media (min-width: 769px) and (max-width: 1024px) {
    .rowHome {
        flex-direction: column;
        align-items: left;
        padding-left: 0px;
    }
} */
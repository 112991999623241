.cRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.column {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 140px
}

.calButton {
        border: none;
        width: 50px;
        background: rgba(241, 239, 241, 0.01);
        border-radius: 0px;
        margin-left: 5px;
        padding: 2.5px 0px;
        font-size: 10px;
        cursor: pointer;
        outline: 1px solid;
        letter-spacing: 1.5px;
}


.nov11-container {
    padding: 20px;
    font-family: Arial, sans-serif;
    width:100%;
 }

.brand-section {
    margin-bottom: 10px;
}

.brand-section h2 {
    cursor: pointer;
    border-bottom: 2px solid #ccc;
    padding: 1px;
    transition: background-color 0.3s ease;
    font-weight: bold;
    font-size: 14px;
    width: 525px
}

.brand-section h2:hover {
    background-color: #26619C;
}

.products-list {
    margin-top: 10px;
    padding-left: 20px;
    width: 500px;
}

.product-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    font-size: 12px;
   
}

.product-info {
    display: flex;
    flex-direction: column;
   
}

.product-name {
    font-weight: bold;
}

.product-qty {
    font-size: 0.9em;
    color: #666;
}

.product-status {
    margin-left: 10px;
}

.status-options {
    display: flex;
    gap: 10px;
    font-size: 11px
}

.MuiSvgIcon-root {
    fill: #FF00FF !important;
    /* Change the icon color */
}

.add-button {
    font-size: 24px;
    background-color: #ee9568;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 5px;
    cursor: pointer;
}

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.popup-content {
    background-color: #ee9568;
    padding: 20px;
    border-radius: 10px;
    width: 350px;
}

form {
    display: flex;
    flex-direction: column;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    margin-bottom: 5px;
    display: block;
    color: #000;
    font-weight: bold;
}

.form-group input,
.form-group select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
}



.rowPlain {
    display: flex;
    flex-direction: row;
    gap: 0.5em;
}

@media (min-width: 481px) and (max-width: 768px) {
.brand-section h2 {
        font-size: 11px !important;
    }

.product-name {
    font-size: 11px !important;
}

.product-qty {
    font-size: 11px !important;
}

.products-list {
    width: 480px
}


}

@media (max-width: 480px) {
    .brand-section h2 {
        font-size: 11px;
  
    }

    .products-list {
        width: 100%
    }
}
.rowPlain {
    display: flex;
    flex-direction: row;
    gap: 0.5em;
  }

  .responsive-div {
    width: 100%;
    max-width: 100%;
    overflow-wrap: break-word;
    box-sizing: border-box;
    font-size: 1em;
  }

.responsiveCard {
    width: 375px;    
    border-radius: 5px;
    padding-top: 7.5px;
    margin: 2.5px;
}

.responsiveCard h1 {
    color: white;
    font-weight: 400;
    font-size: 1.1em;
    margin-top: 0px;

}

.rowHome{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1.2em;
 
}

/* text */
.h2sk {
    font-size: 15px;
  
}

/* tables */

.tablePantry {
    border-spacing: 0;
    border-collapse: collapse;
}

th, td {
    border-bottom: 0.5px solid;
    padding: 8px
}

.wrapped-header {
    white-space: normal;
    width: 50px;
    word-wrap: break-word;
    font-size: 10px;
    text-align: left;
}

/* search */
.searchBox {
    border: 1px solid;
    background-color: transparent;
    font-size: 1em;
}

.ask {
    padding: 20px
}


/* buttons */
.askButton {
    border: 2px solid;
    border-radius: 30px;
    max-width: 300px;
    padding: 0px 15px;
    display: flex;
    justify-content: center;
    font-size: .9em;
    
    /* background: linear-gradient(135deg, hsla(39, 11%, 75%, 1) 0%, hsla(38, 14%, 89%, 1) 70%, hsla(200, 10%, 94%, 1) 100%);
  */
}

/* homeCard */
.homeCard {
    padding: 0em 0em;
    width: 13em;
    /* border-left: 2px solid */
}

.homeCard h1 {
    font-weight: 800;
    font-size: 1.15em;
    margin-bottom: -0.7em;
    line-height: 1.7em;
    padding-left: 5px;
    margin-top: 0px;
    /* border-bottom: 4px solid#ab966d; */
    background: linear-gradient(90deg, hsla(40, 27%, 55%, 1) 52%, hsla(40, 27%, 55%, 1) 70%, hsla(200, 10%, 94%, 1) 110%);
}

.homeCardList {
    line-height: 1.75em;
    margin-top: 1.2em;
    font-size: 0.95em;
    display: block;
}

.homeCardList a {
    color: inherit;
    line-height: inherit;
    font-size: inherit;
}


.saratogaTH {
    font-size: 8px;
    color: #000;
    text-align: center
}

.saratogaTR {
    font-size: 10px;
    color: #000;
    text-align: left
}


/* kit */
.kit {
    max-width: 7em;  
}

.kitCard{
max-height: 100px;
padding: 0 1em;
display: flex;
align-items: center;
border: 1px solid

}

.gradientNeon {


    background: hsla(152, 100%, 50%, 1);
    
        background: linear-gradient(90deg, hsla(152, 100%, 50%, 1) 0%, hsla(186, 100%, 69%, 1) 100%);
    
        background: -moz-linear-gradient(90deg, hsla(152, 100%, 50%, 1) 0%, hsla(186, 100%, 69%, 1) 100%);
    
        background: -webkit-linear-gradient(90deg, hsla(152, 100%, 50%, 1) 0%, hsla(186, 100%, 69%, 1) 100%);
    
        filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#00FF87", endColorstr="#60EFFF", GradientType=1);
}


/* Mobile devices */
@media (max-width: 480px) {
    .rowPlain {
        flex-direction: column;
    }
    .responsive-div {
        max-width: 200px;
        font-size: .7em
    }
    .searchBox {
        font-size: 0.6em;
        }
    .rowHome {
        flex-direction: column;
        align-items: left;
        padding-left: 0px;
    }
    .homeCard h1 {
        font-weight: 800;
        font-size: 0.9em;
        line-height: 1.5em
    }
    .kitCard {
        height: 70px;
        max-width: 12em;

    }
  
}

/* iPads, Tablets */
@media (min-width: 481px) and (max-width: 768px) {
   
    .rowPlain {
            flex-direction: column;
        }
    .searchBox {
        font-size: 0.6em;
    }
    .rowHome {
        flex-direction: column;
        align-items: left;
        padding-left: 0px;
    }
    .homeCard h1 {
        font-weight: 800;
        font-size: 0.9em;
        line-height: 1.5em
    }
}

/* Small screens, laptops */
@media (min-width: 769px) and (max-width: 1024px) {
        .rowHome {
        flex-direction: column;
        align-items: left;
        padding-left: 0px;
    }
    
    .searchBox {
        font-size: 0.6em;
    }
    .homeCard {
        width: 12em

    }
    .homeCard h1 {
        font-weight: 800;
        font-size: 0.9em;
        line-height: 1.5em
    }
}

/* Extra large screens, TV */
@media (min-width: 1201px) {
    .searchBox {
        font-size: 1.1em;
    }
}


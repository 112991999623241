  .responsive-div {
      width: 100%;
      max-width: 100%;
      overflow-wrap: break-word;
      box-sizing: border-box;
      font-size: 1em;
  }


.product-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 1rem;
    margin-top: 10px
}

  .product-row1 {
    display: flex;
    flex-direction: row;
    gap: 1em;
}

  .product-lifestyle{
      display: flex;
      flex-direction: row;
      gap: 0.5em;
  }

.product-column1 {
    display: flex;
    flex-direction: column;
    gap: 0em;
}

.product-card {
    max-width: 250px;
    border: 8px solid #b5b5b5;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 18px;
    overflow: hidden;
    background: white;
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.product-card2 {
   
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 18px;
    overflow: hidden;
    background: white;
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.product-card3 {
    border-radius: 18px;
    overflow: hidden;
    background: white;
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.product-card-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0rem;
    background-color: #f8f8f8;
    border-bottom: 1px solid #e0e0e0;
    color: #333;
    font-weight: bold;
}

.product-card-header span {
    /* use this for alignment or add specific classes or styles for icons */
}

.product-image {
    width: 100%;
    /* Adjust as needed */
    height: auto;
    object-fit: cover;
    /* This ensures that the aspect ratio of the image is maintained */
}

  .image-lifestyle {
    width: "50px"
  }

.product-title {
    font-size: 12px;
    font-weight: 500;
    margin: 0.15rem 0rem;
    text-align: center;
    color: #333;
}

.product-card-footer {
    display: flex;
    justify-content: space-around;
    padding: 1rem 0em;
    background-color: #000;
    border-top: 1px solid #e0e0e0;
}

.product-card-footer button {
    background: none;
    border: none;
    color: #ffffff;
    font-size: 9px;
    padding: 0.5rem;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Center the content horizontally */
    justify-content: center;
    /* Center the content vertically */
}

.product-card-footer button:hover {
    text-decoration: underline;
}

.variant {
    background-color: rgba(145, 163, 176, 0.18);
    border: 3px solid #ab966d;
    border-radius: 3px;
    width: 5em;
    color: white;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.1em 0.5em;
    margin-top: -10px

}

.goldBorder {
    border: 2px solid #ab966d;

}

.description-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
    margin-top: 10px
}
.det {
    color: white
}

.select {
    border: 2px solid #ab966d;
    background: transparent;
    color: #ab966d;
    padding: 5px;
}


/* Mobile devices */
@media (max-width: 480px) {

}

/* iPads, Tablets */
@media (min-width: 481px) and (max-width: 768px) {
    .product-grid {
            display: flex;
            flex-direction: column;
            margin-top: 10px
        }

    .product-card {
        max-width: 200px;
        border: 8px solid #b5b5b5;
        margin: 1rem 0;
  
    }
}

/* Small screens, laptops */
@media (min-width: 769px) and (max-width: 1024px) {
.product-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 1rem;
        margin-top: 10px
    }

}

/* Extra large screens, TV */
@media (min-width: 1201px) {
.product-grid {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 1rem;
        margin-top: 10px
    }

        .product-card2 {
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
            border-radius: 18px;
            overflow: hidden;
            background: white;
            margin: 1rem 0;
            display: flex;
            flex-direction: column;
            align-items: stretch;
        }
}
.colHeader {
    width: 8em;
    white-space: normal;
    word-wrap: break-word;
    overflow-wrap: break-word;
    font-size: 11px;
    font-weight: 600;
    text-align: left;
    padding-left: 5px;

}
.shoppableTV {
    width: 810px;
    position: relative;
    margin-left: 10px
}

.shoppableTV2 {
    width: 800px;
    max-width: 100%;
    margin: 1%
}

.video-line {
    width: 100%
}

#video-container:fullscreen video {
    height: 100vh;
    width: 100vw;
}

#video-chat-container:fullscreen .footer {
    width: 200px;
}

#top-overlay {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
}

#bottom-overlay {
    display: flex;
    align-items: center;
    position: absolute;
    justify-content: flex-start;
    bottom: 0;
    left: 0;
    right: 0;
    background: black;
    color: white;
    opacity: .8;
    margin-left: 0.5em
}

#video-container:fullscreen #top-overlay {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
}

#video-container:fullscreen #bottom-overlay {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;

}

#video-container:fullscreen #chat {
    background-color: rgba(0, 0, 0, 0.3);
    max-width: 400px;
    width: 400px;
}

/* animation */
@keyframes popUpFromBottom {
    from {
        transform: translateY(100%);
        /* Starts off-screen or below its final position */
        opacity: 0;
    }

    to {
        transform: translateY(0);
        /* Moves to its final position */
        opacity: 1;
    }
}

.pop-up-from-bottom {
    animation: popUpFromBottom 0.5s ease-out forwards;

}

.footer {
    width: 90px
}

/* responsiveness */
@media (max-width: 600px) {

    .shoppableTV {
        width: 400px
    }

    #video-container:fullscreen .footer {
        width: 70px;
    }

    .footer {
        width: 55px
    }
}

@media (max-width: 428px) {

    .shoppableTV {
        width: 350px
    }

    #video-container:fullscreen .footer {
        width: 70px;
    }

    .footer {
        width: 48px
    }
}

@media (max-width: 575.98px) {
    #video-container:fullscreen #chat {
        max-width: 200px;
        width: 200px;
    }

    #video-container:fullscreen #chat-input-container {
        width: 200px !important;
    }
}

#video-container:fullscreen #chat-container {
    /* height: 80vh; */
    /* position: fixed; */
    /* top: calc(50vh - (80vh / 2)); */
    /* right: 10px; */
    /* color: #ffffff; */
}

#video-container:fullscreen #chat-input,
#video-container:fullscreen #submit {
    background: rgba(0, 0, 0, 0.3);
    color: #ffffff;
    border: 1px solid rgb(var(--bs-white-rgb));
}
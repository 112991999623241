.hRow {
    display: flex;
    flex-direction: row;
}

.chartRow {
   width: 35%;
    border: 1px solid gray;
    margin: 1px;
    padding: 2px 5px

}

.fullColumn {
    display: flex;
        flex-direction: column;
        justify-content: center;
}

.hColumn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 30em;
    margin-left: 0.5em;
}

.hCard {
    display: flex;
    flex-direction: row;
    border-radius: 14px;
    width: 20em;
    justify-content: center;
    margin-bottom: 1em;
    font-weight: 600;
}

.vHeader {
    font-weight: 600;
    color: white;
}

.vText {
    font-weight: 400;
    font-size: 0.7em
}

.expand {
    margin-top: 0px
}

.metricRow {
    display: flex;
    flex-direction: row;
    /* margin-top: -15px */
}

.statRow {
    display: flex;
    flex-direction: row;
}

.audienceBox {
    text-align: center;
    width: 30%
}

.metricColumn {
    display: flex;
    flex-direction: column;
}

.metricCard {
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
    margin: 2.5px;
}

.metricCard h1 {
    color: white;
    font-weight: 300;
    font-size: 1em;
    margin-top: 0px;
   
}

.metricCard h2 {
    color: white;
    font-weight: 400;
    font-size: 0.9em;
}

.tallMetricCard {
    height: 375px;
    max-width: 400px;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
    margin: 2.5px;
}

.tallMetricCard h1 {
    color: white;
    font-weight: 300;
    font-size: 1em;
    margin-top: 0px;

}

.tallMetricCard h2 {
    color: white;
    font-weight: 400;
    font-size: 0.9em;
}

.miniMetricCard {
    width: 80px;
    text-align: center;
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
    background-color: rgba(54, 42, 72, 0.609);
    font-size: 0.9em
}

.miniStat {
    color: lavender;
    font-weight: 600;
    font-size: 1em;
    margin-top: 5px;
    margin-bottom: -15px;
}


.miniMetricCard2 {
    width: 100px;
    margin-top: -15px;
    text-align: center;
    border: 0.5px solid rgba(255, 255, 255, 0.5);
    border-radius: 5px;   
    margin-right: 10px;
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
    flex-direction: row
}

.metricTable {
    display: flex;
    justify-content: right;
}
.number {
    font-weight: 500;
    font-size: 1.1em
}

.bargraph {
    height: 100px;
    background-color: aqua;
}

/* Mobile devices */
@media (max-width: 480px) {

    .hRow {
        flex-direction: column;
        }

    .chartRow {
        width: 80%;
    }
    .audienceBox {
        width: 100%
    }
    .metricRow {
        flex-direction: column;
    }
    .metricCard h2 {
        color: white;
        font-weight: 400;
        font-size: 0.7em;
    }
    .number {
        font-size: 0.9em
    }
    .tallMetricCard {
        height: 425px
    }
    .miniMetricCard2 {
        margin-top: 0px;
        margin-bottom: 10px;
        margin-left: 0px;
        width: 300px
    }
    .miniMetricCard2 h2 {
        font-size: 0.9em
    }
}

/* iPads, Tablets */
@media (min-width: 481px) and (max-width: 768px) {
   
    .hRow {
          
            flex-direction: column;
        }
    

.chartRow {
    width: 50%;

}

    .metricRow {
        flex-direction: column;
    }
    .metricCard h2 {
        color: white;
        font-weight: 400;
        font-size: 0.7em;
    }
    .number {
    font-size: 1em
    }

    .miniStat {
        color: lavender;
        font-size: 1em;
        margin-top: 0px;
        margin-bottom: -10px;
    }

    .tallMetricCard {
       height: 425px
    }
/* 
    .tallMetricCard h1 {
        color: white;
        font-weight: 300;
        font-size: 0.8em;
        margin-top: 0px;

    } */

    /* .tallMetricCard h2 {
        color: blue;
        font-weight: 400;
        font-size: 0.9em;
    } */

    .miniMetricCard2 {
        margin-top: 0px;
        margin-bottom: 10px;
        margin-left: 0px;
        width: 200px
    }

    .miniMetricCard2 h2 {
        font-size: 0.9em
    }

}

/* Small screens, laptops */
@media (min-width: 769px) and (max-width: 1024px){
    .metricRow {
        flex-direction: column;
    }
    
    .metricCard h2 {
        color: white;
        font-weight: 400;
        font-size: 0.7em;
    }
    .number {
        font-size: 1em
    }

    .tallMetricCard {
        height: 425px
    }

    .miniMetricCard2 {
        margin-top: 0px;
        margin-bottom: 10px;
        margin-left: 0px;
        width: 200px
    }

    .miniMetricCard2 h2 {
        font-size: 0.9em
    }
.expand {
    margin-top: 0px
}
}   

/* Extra large screens, TV */
@media (min-height: 1201px) {
    .searchBox {
        font-size: 1.1em;
    }
}
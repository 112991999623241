@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@100;200;300;400;500;600;700;800;900&display=swap");

@import url("https: //cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css");

@import url("https: //cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css");

/*css variable*/
:root {
  --sk-custom-color-700: #1e6488;

}

/* Element Selector */
.app {
  display: flex;
  position: relative;
}
html,
body,
#root,
h1 {
  color: magenta
}

h2 {
  color: orange;
  font-weight: 400;
}

h3 {
  color: yellow;
  font-size: .9em;
  font-weight: 300
}

.sh1 {
  font-size: 1.5em;
  font-weight: 700
}

.sh2 {
  font-size: 1.3em;
  font-weight: 600
}

.sh3 {
  font-size: 1.2em;
  font-weight: 600
}

.sh4 {
  font-size: 1.1em;
    font-weight: 600;
  color: #000
}

.sh5 {
  font-size: 0.9em;
  font-weight: 600; 
  color: #000
}

.sh6 {
  font-size: 0.9em;
  font-weight: 400;
  color: #000
}

.sp {
    font-size: 1em;
    font-weight: 400;
}

/*Text*/
.textUpper {
  display: flex;
  justify-content: center;
  font-family: "Source Sans Pro";

}

.stat {
  font-size: 30px;
}

.statNumber {
  font-size: 1em;
  margin-left: 10px;

}

.resourceHeader {
  margin-bottom: -15px;
  font-size: 1.2em
}

.data {
  margin-left: 10px;
  font-size: 0.9em
}

.notSureJustifyContent {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rightJustifyContent {
  display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 300px
}

/*Position*/

.above {
  position: relative;
  z-index: 1000;

}

.below {
  position: relative;
  z-index: -1000;
  font-weight: 800;
}


.leftAlignFlexItem {
  align-self: flex-start;
}

/*Pseudo Selectors*/
a:link {
  text-decoration: none;
  color: #ab966d;
  font-size: 16px;

}

a:visited {
  color: #ab966d;
}

a:link.BDlinks {
  text-decoration: none;
  color: #e08235;
  font-size: 19px;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: "300"

}

a:visited.BDlinks {
  text-decoration: none;
  color: #e08235;
  font-size: 19px;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: "300"

}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
}

/* Class Selectors */
.content {
  height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
}

.header {
  color: #d1ccdc;
}

/*SN*/
.botmLabels {
  font-size: 12px;
  color: pink !important;
}

.SNheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  border-bottom: 0.5px solid white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1)
}

.header-buttons {
  display: flex;
  gap: 10px;
}

.header-buttons button {
  background: none;
  border: none;
  font-size: 12px;
  cursor: pointer;
  /* transition: color 0.3s; */
}

.header-buttons button:hover {
  color: #007BFF;
}

.header-icons img {
  margin: 0 5px;
  max-height: 30px;
}

.survey-container {
  width: 60%;
  height: 20em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 1rem auto;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ab966d;
  box-shadow: 0px 2px 4px rgba(0,0,0,0.1);
  font-size: .75em;
  
}

.survey-contents {
  display: flex;
  justify-content: top;
  align-items: center;
  flex-direction: column;
  padding: 5px;
  color: #fff;
  font-weight: 400;
  font-size: 1em;
}

.question {
  display: none;
}

.question.active {
  display: block;
}

.choice-label {
  display: block;
}

.surveyB {
  width: 60%;
  background: #ab966d;
  border-radius: 40px;
  /* border: 1px solid rgba(255, 255, 255, 0.5); */
  color: black;
  transition: opacity 0.3s ease;
  font-size: "8px";
  cursor: pointer;
}

.surveyB:hover {
  background-color: #fff
}

/*img */
.nb {
  width: 60%;
  display: center;
  margin-bottom: 10px;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* 4 columns */
  grid-gap: 10px;
  /* spacing between images */
  margin: 20px;
}

.image-container img {
  width: 150px;
  height: auto;
  cursor: pointer;
  /* Changes cursor on hover */
  border: 1px solid #ddd;
  border-radius: 4px;
}


.image-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.image-row img {
  width: 100%;
  max-width: 200px;
  height: auto;
  margin: 10px;
  padding: 10px;
  background-color: #0f141e;
  border-radius: 25px;
}

/*Formatting & Containers*/
.Stackcontainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.IconContainer {
  display: flex;
  flex-direction: row;
  margin-top: 2em;
  margin-bottom: -15px;
  gap: 10px;
}

.KeyContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 30em;
  height: 4em;
  /* background-color: #fff; */
  /* border: 1px solid black; */
  padding: 5px 5px;
  /* border-radius: 20px */

}

.KeyIcon {
  width: 1.8em;
  height: 1.8em;
  margin-bottom: -1.5em;
  padding: 3px;
  background-color: #fff;
  border-radius: 30px
}

.helpBox {
  border: 1px solid;
  border-radius: 10px;
  padding: 10px;
  width: 500px;
  margin-top: 10px
 }


.row {
  display: flex;
  gap: 40px;
  flex-direction: row;
}

.row2 {
  display: flex;
  gap: 2em;
  flex-direction: row;

}

.row3 {
  display: flex;
  gap: .7em;
  flex-direction: row;
}

.row10 {
  display: flex;
  gap: 10px;
  flex-direction: row;
  margin-bottom: 10px;
}

.row0 {
  display: flex;
  /* gap: 10px; */
  flex-direction: row;
}

.rowBorder {
  display: flex;
  flex-direction: row;
  gap: 20px;
  border-left: 1px solid;
  padding-left: 1em;
  }


.column {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 140px
}

.statHeader{
font-size: 1.2em;
font-weight: 600
}

.statHeader2 {
  font-size: 1.1em;
  font-weight: 400
}

.columnFlexStart {
    display: flex;
    flex-direction: column;
    /* align-items: flex-start; */
    justify-content: flex-start;
    color: white;
}

/* .programmingCircle {
  display: inline-block;
} */

.enlarge {
  transform: scale(1.2);
}

.visibleBorder {
    /* border: '3px solid rgba(255, 255, 255, 0.5)',  */
    border: 1px solid;
    border-radius: 10px;
    padding: 5px
}

.visibleBorder2 {
  /* border: '3px solid rgba(255, 255, 255, 0.5)',  */
  border: 1px solid;
}

.roundBorder {
  border: 1px solid;
  border-radius: 50px;
  padding: 8px
}

.bottomBorder {
border-bottom: 1px solid;
margin-bottom: 8px
}

.Item {
  padding: 10px;
  text-align: center;
  margin-top: -20px;
}

.Icon {
  /* color: blue; */
  font-size: large;
}

.IconText {
  font-size: 0.8em;
  font-weight: 600;
  margin-top: 0px;
  }


.Scontainer {
  display: flex;
  flex-direction: row;
  margin: 2em;
}

.show {
  width: 300px;
}

.Showcontainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: baseline;
  width: 60%;
}

.Showcontainer h1 {
  font-size: 1.2em;
}

.Showcontainer h2 {
  font-size: 1.2em;
  font-weight: 600;
}

.Showcontainer p {
  font-size: 1em;
}

.Showcontainer2 {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
}

.Showcontainer3 {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
  margin-left: 25px;
}

.Showcontainer3 h1 {
  font-size: 1em
}

.Hcontainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 3em;
  padding: 10px;

}

/* popup */
.popup-content {
  margin: auto;
  width: 50%;
  padding: 5px;
  color: #fff;
}

#popup-2 {
  background-color: #E5E4E2;
  width: 300px;
  margin: 10px
  
}

.popup-content2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 5px;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}

[data-popup='tooltip'].popup-overlay {
  background: transparent;
}



/* data visualizations */
.chart-container {
  padding: 10px;    
  max-width: 2000px;  
}

.barChart {
  padding: 10px;
  max-width: 40em;
}

.donut {
margin-left: 10px;
display: flex;
}

.shan {
  border: 3px solid #ab966d;
  margin: 20px;
  border-radius: 5%;
}

.years {
  background-color: rgb(180, 180, 180, 0.2);
  display: flex;
  justify-content: flex-start;
  text-align: left;
}

/*Chart*/
.chartMenu {
  width: 100vw;
  height: 40px;
  background: #1A1A1A;
  color: rgba(54, 162, 235, 1);
}

.chartMenu p {
  padding: 10px;
  font-size: 20px;
}

.chartCard {
  width: 100vw;
  height: calc(100vh - 40px);
  background: rgba(54, 162, 235, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  color: colors.SKcustom[700];
}

.chartBox {
  width: 700px;
  padding: 20px;
  border-radius: 20px;
  border: solid 3px rgba(54, 162, 235, 1);
  background: white;
}

/*StatCards*/
.white-box {
  background-color: white;
  border: 1px solid black;

  padding: 12px;
  margin-bottom: 20px;
  max-width: 325px;
  border-radius: 10px;

  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: auto;
  grid-gap: 5px;
}

.stat-card {
  background-color: #f5f5f5;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  max-width: 325px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.stat-card-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: 
}

.stat-icon {
  color: black;
}

.shannon {
  color: blue;
  width: 40px;
  background-color: blue;
  font-size: 1200px;
}

.total-sales {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
}

.white-box2 {
  background-color: white;
  border: 1px solid black;

  padding: 12px;
  max-width: 325px;
  border-radius: 10px;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 5px;
}

.rowCard {
  margin-left: 10px;
}

.columnCard {
  flex: 1;
  color: black;
  font-size: 17px;
  /* background-color: green; */
}

.columnCard2 {
  flex: 1;
  color: black;
  font-size: 24px;
  font-weight: 600;
  margin-left: 10px;
} 

.two-column {
  display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    max-width: 650px;
    gap: 10px;
}

.topSellerHeader {
  font-size: 1.3em;
  font-weight: 600;
  margin-bottom: -10px;
  /* display: flex;
  justify-content: center; */
}

/*Tables*/
.headers {
  font-size: .9em;
  padding-left: 5px
}

.cells {
  border: 1px solid;
  padding: 6px;
  font-size: .85em;
}

.t_header {
  font-size: 1em;
}

.sktable {
  font-size: 1em;
  width: 70%;
  margin: 10px;
}

.tableSK {
  border-spacing: 0;
  border-collapse: collapse;

}

td {
  padding-left: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  line-height: 13px;
  font-size: 11px;
  /* border-right: 1px solid #ab966d; */
}

.campcolM {
  width: 11em;
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
  font-size: 11px;
  font-weight: 500;
  text-align: left;


}

.campcolS {
  width: 8em;
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;  
  font-size: 11px;
  font-weight: 500;
  text-align: left;

}

.campcolCenter {
  width: 8em;
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
  font-size: 11px;
  font-weight: 500;
  text-align: left;

}


.campcolxs {
  width: 9em;
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
  font-size: 11px;
  font-weight: 500;
  text-align: left;

}

.campcolH {
  width: 8em;
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
  font-size: 11px;
  font-weight: 300;
  text-align: left;
  padding-left: 5px;

}

.subcolS {
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
  font-size: 10px;
  font-weight: 400;
  text-align: left;
  padding-left: 3px;
  padding-right: 3px;  
}

.campcell {
  box-sizing: border-box;
  border-left: 0.1px solid;
  font-size: 10px
}

.miniGrid {
  display: flex;
  
}

/* component specific */

.dz {
  margin-left: 20px;;
  font-size: 1.2em;
}

.BDsteps {
  list-style: none;
  font-size: 20px;
}

.BDlinks {
  list-style: none;
  font-size: 20px;
}

.Shannon {
  color: "#";
  font-size: 20px;
}

.carousel .control-next.control-arrow:before {
  border-left: 20px solid #860c0c !important;
}

.carousel .control-prev.control-arrow:before {
  border-right: 20px solid #860c0c !important;
}

.carousel .control-dots {
  background-color: gray;
}

.squares {
  width: 12em;
  align-items: flex-start;
}

/* timeline */

.pinho {
  font-size: 1.28em;
}

.timeline {
  position: relative;
  margin: 20px auto;
 
  max-width:1500px;
  padding: 5px 0px;
  background: none;
}

.timeline:before,
.timeline:after {
  position:absolute;
  left:50%;
  transform: translateX(-50%);
  width:15-px;
  height:40px;
  text-align: center;
  line-height:40px;
  background:#555;
  color:#fff;
  z-index: 5;
}

.timeline:before {
content:"";
top:0px;
}

.timeline:after {
  content:"";
  bottom:0px;

}

.timeline .timeline-blocks {
  margin: 65px 0x;
}

.timeline .timeline-blocks:before {
content: "";
position: absolute;
top:50%;
left:50%;
transform:translate(-50%,-50%);
width:2px;
height:100%;
border-left: 2px solid #ab966d
}

/* each block*/
.timeline .timeline-blocks .block{
  position: relative;
  width:calc(50% - 20px);
  margin:20px 0px;
  border:0.5px solid #B8B1BB;
  border-radius: 10px;
  box-shadow: 0px 2px 8px 2px rgba (0,0,0,0.1);
  /* background: linear-gradient(to left, rgba(245, 245, 245, 1), rgba(128, 128, 128, .5)); */
  /* color: black; */
  
}

.timeline .timeline-blocks .block:hover {
transform: scale(1.2);
background: rgba(122, 122, 122, 1)

}

.timeline .timeline-blocks .block:nth-child(even) {
  left:calc(50% + 20px);
}

/* balls */
.timeline .timeline-blocks .block:before {
  content:"";
  position:absolute;
  top:0px;
  right:-30px;
  width:20px;
  height:20px;
  border-radius:50%;
  background:#ab966d;
}

.timeline .timeline-blocks .block:nth-child(even):before {
  left:-30px;
}

/* header */
.timeline .timeline-blocks .block .header {
  padding:10px;
  border-radius:10px;
  font-weight: bold;
}

/* event text */
.timeline .timeline-blocks .block .header h2 {
  font-size:20px;
  color: #0D3D67;
  margin-bottom:2px;
  margin-top: 1px;

}

/* date text */
.timeline .timeline-blocks .block .header > p {
  position:absolute;
  font-size:25px;
  color: pink;
  font-weight: 600;
  font-style:italic;
  background: none;
  /* border:1px solid #eee; */
  /* padding:2px 10px; */
  border-radius:10px;
}

.timeline .timeline-blocks .block:nth-child(odd) .header > p {
  right:-110px;
  top:0px;
}

.timeline .timeline-blocks .block:nth-child(even) .header > p {
  left: -110px;
  top: 0px;
}

/* description */
.timeline .timeline-blocks .block > p {
  padding:10px;
  font-size:16px;
  color: pink;
}

.timeline .timeline-blocks .block:nth-child(1) .header,
.timeline .timeline-blocks .block:nth-child(1):before {
  background:#1DA186
}

.timeline .timeline-blocks .block:nth-child(1) .header p {
  color:#1DA186;
}

.timeline .timeline-blocks .block:nth-child(2) .header,
.timeline .timeline-blocks .block:nth-child(2):before {
  background: #25D396;
}

.timeline .timeline-blocks .block:nth-child(2) .header p {
  color: #25D396;
}

/* ball */
.timeline .timeline-blocks .block:nth-child(3) .header,
.timeline .timeline-blocks .block:nth-child(3):before {
  background: #7AE876;
}

/* date */
.timeline .timeline-blocks .block:nth-child(3) .header p {
  color: #7AE876;
}

/* ball */
.timeline .timeline-blocks .block:nth-child(4) .header,
.timeline .timeline-blocks .block:nth-child(4):before {
  background: #B5E876;
}

/* date */
.timeline .timeline-blocks .block:nth-child(4) .header p {
  color: #B5E876;
}

/* ball */
.timeline .timeline-blocks .block:nth-child(5) .header,
.timeline .timeline-blocks .block:nth-child(5):before {
  background: #F4F269;
}

/* date */
.timeline .timeline-blocks .block:nth-child(5) .header p {
  color: #F4F269;
}

/* buttons */
.dlButton {
  height: 20px;
  font-size: 11px;
  margin-bottom: 10px;
  margin-left: 5px;
}

.neonButton {
  border: none;
  margin-top: 5px;
  margin-bottom: 5px;
  background: rgba(241, 239, 241, 0.01);
  border-radius: 20px;
  color:rgba(255, 0, 255, 0.8);
  padding: 5px 10px;
  font-size: 11px;
  cursor: pointer;
  outline: solid 1px;
  transition: all 0.3s ease;
  letter-spacing: 1.5px;
}

.neonButtonSquare {
  border: none;
  margin-top: 5px;
  margin-bottom: 5px;
  background: rgba(241, 239, 241, 0.01);
  /* border-radius: 50%; */
  padding: 2px 5px;
  font-size: 10px;
  cursor: pointer;
  outline: 1px dotted;
  transition: all 0.3s ease;
  letter-spacing: 1.5px;
}

.neonButton2 {
  border: none;
  margin-top: 15px;
  margin-bottom: 5px;
  margin-left: 80%;
  background: rgba(241, 239, 241, 0.01);
  border-radius: 20px;
  color: rgba(255, 0, 255, 0.8);
  padding: 5px 10px;
  font-size: 10px;
  cursor: pointer;
  outline: solid;
  transition: all 0.3s ease;
  letter-spacing: 1.5px;
}

.orangeButton {
    /* margin-top: 15px; */
    border: none;  
    margin-bottom: 15px;
    background: rgba(241, 239, 241, 0.01);
    border-radius: 20px;
    color: rgba(233, 165, 30, 0.8);
    /* padding: 5px 10px; */
    font-size: 10px;
    /* outline: 1.5px solid rgba(233, 165, 30, 0.7); */
    transition: all 0.3s ease;
    letter-spacing: 1px;
}

.sortButton {
  /* margin-top: 15px; */
  border: none;
  margin-bottom: none;
  background: rgba(241, 239, 241, 0.01);
  border-radius: 20px;
  color: rgba(233, 165, 30, 0.8);
  /* padding: 5px 10px; */
  font-size: 10px;
  /* outline: 1.5px solid rgba(233, 165, 30, 0.7); */
  transition: all 0.3s ease;
  letter-spacing: 1px;
}

.noBorderButton {
    /* margin-top: 15px; */
      border: none;
      margin-bottom: 15px;
      background: rgba(241, 239, 241, 0.01);
      border-radius: 20px;
           /* padding: 5px 10px; */
      font-size: 10px;
      /* outline: 1.5px solid rgba(233, 165, 30, 0.7); */
      transition: all 0.3s ease;
      letter-spacing: 1px;
    }

.sliderButton {
  /* margin-top: 15px; */
  border: none;
  margin-bottom: 15px;
  background: rgba(241, 239, 241, 0.01);
  border-radius: 20px;
  color: rgba(233, 165, 30, 0.8);
  /* padding: 5px 10px; */
  font-size: 10px;
  /* outline: 1.5px solid rgba(233, 165, 30, 0.7); */
  transition: all 0.3s ease;
  letter-spacing: 1px;
}



/* responsiveness */
@media (max-width: 600px) {

  
     .footer-2 {
       left: 20%;
       /* Adjust left position for footer 2 */
     }

  h1 {
    font-size: 1em !important;
  }

  h2 {
    font-size: 20px !important;
  }

  h3 {
    font-size: .8em;
  }

  h5 {
    font-size: 25px !important;
  }

  h6 {
    font-size: 20px !important;
  }

  .sh1 {
    font-size: 1em;
    font-weight: 700;
    margin-bottom: 5px
  }

  .sh2 {
    font-size: 1em;
    font-weight: 600
  }

  .sh3 {
    font-size: 0.9em;
    font-weight: 600
  }

  .resourceHeader {
    margin-bottom: -15px;
    font-size: 8px
  }

  /*Layout*/

.row2 {
  display: flex;
  gap: 0px;
  flex-direction: column;
  width: 800px
}

.row3 {
  display: flex;
  gap: 0px;
  flex-direction: column;
}


  .Scontainer {
    /* flex-direction: column; */
    flex-wrap: wrap;
    width: 40%;

  }

  .IconContainer {
    display: flex;
    flex-direction: column;
     width: 20%;
    
  }

    .helpBox {
      border: 1px solid;
      border-radius: 10px;
      padding: 10px;
      width: 350px;
      margin-top: 10px
    }

  .white-box {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    max-width: 150px;
  }

  .columnCard {
    font-size: 14px;
  }

  .columnCard2 {
    font-size: 18px;
  }

  .twoColumn {
    max-width: 150px;
  }

/*Classes Responsive*/
.Hcontainer {
  flex-direction: column;
  width: 400px
}

.squares {
  width: 10em;
  height: 12em;
}

.Hatcontainer {
  margin-left: .1em;
}

/*Timeline Responsive*/

  .timeline:before,
  .timeline:after {
    left:20px;
    transform:translateX(-2px);
  }

  .timeline .timeline-block {
    padding-left:20px;
  }

  .timeline .timeline-blocks:before {
    left:20px;
  }

  .timeline .timeline-blocks .block {
    width:100%
  }

  .timeline .timeline-blocks .block:before {
    left:-30px
  }

  .timeline .timeline-blocks .block:nth-child(even) {
    left: 0px;
  }

  .timeline .timeline-blocks .block .header p {
    position:static;
    background: transparent;
    border: none;
    color:#eee !important;
    padding:0px; 
    margin-top:5px
  }

    .pinho {
      font-size: 0.9em;
    }
}



@import 'react-big-calendar/lib/sass/styles';

// Your custom styles go here

//selector with nested selectors
.calendar-custom-style {

    .rbc-event {
        background-color: orange;
        color: white;
    }

    // month title
    .rbc-toolbar .rbc-toolbar-label {
        color: #858585; 
        font-weight: 600;
   
    }

    .rbc-btn-group {
        background-color: rgba(255, 255, 255, 0.6);
        
    }

    .rbc-month-header .rbc-header {
        color: #858585
    }

    .rbc-date-cell {
        color: #858585
    }

    .rbc-off-range-bg {
        background-color: transparent
        // background-color: rgba(229, 228, 226, 0.2);
    }

// .rbc-btn-group button:nth-child(4) {

//     display: none;
// }


// .rbc-show-more {
//     display: none !important;
// }

}